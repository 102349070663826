import Vue from 'vue'
import VueI18n from 'vue-i18n'

// import moment from 'moment'
import zhCN from './lang/zh-CN'
import enUS from './lang/en-US'

Vue.use(VueI18n)

export const defaultLang = 'zh-CN'
const messages = {
    'zh-CN': {
        ...zhCN
    },
    'en-US': {
        ...enUS
    }
}
const i18n = new VueI18n({
    silentTranslationWarn: true,
    locale: defaultLang,
    fallbackLocale: defaultLang,
    messages
})

const loadedLanguages = [defaultLang]

function setI18nLanguage(lang) {
    i18n.locale = lang
    // request.headers['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadedLanguageAsync(lang = defaultLang) {
    return new Promise(resolve => {
        if (i18n.locale !== lang) {
            if (!loadedLanguages.includes(lang)) {
                return import(/* webpackChunkName: "lang-[request]" */ `./lang/${lang}`).then(msg => {
                    const locale = msg.default
                    i18n.setLocaleMessage(lang, locale)
                    loadedLanguages.push(lang)
                    // moment.updateLocale(locale.momentName, locale.momentLocale)
                    return setI18nLanguage(lang)
                })
            }
            return resolve(setI18nLanguage(lang))
        }
        return resolve(lang)
    })
}

export default i18n