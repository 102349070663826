<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {},
  created() {
    let systemLang = navigator.language || navigator.userLanguage;
    console.log(systemLang, "initialLang");
    let selectLang = localStorage.getItem("app_language");
    if (systemLang == "zh-CN") {
      if (selectLang) {
        if (selectLang != "zh-CN") {
          this.$store.dispatch("setLang", selectLang);
        }
      } else {
        this.$store.dispatch("setLang", "zh-CN");
      }
    } else {
      if (selectLang) {
        if (selectLang != "en-US") {
          this.$store.dispatch("setLang", selectLang);
        }
        // else{
        //   this.$store.dispatch("setLang", 'en-US');
        // }
      } else {
        this.$store.dispatch("setLang", "en-US");
      }
    }
  },
};
</script>
<style>
body {
  font-size: 16px;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
}
</style>
