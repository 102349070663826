import Vue from "vue";
import Vuex from "vuex";
import storage from "store";
import {
  // i18n
  APP_LANGUAGE,
} from "@/store/mutation-types";
import { loadedLanguageAsync } from "@/locales";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    info: {
      title: "",
      introduce: "",
      beginTime: "",
      endTime: "",
      businessDatas: [],
      zjBusinessDatas: [],
      speechModeStatus: 0,
      allowNonAttendeesStatus: 0,
      materialsDatas: [],
    },
    meetingInfo: {
      speechModeStatus: 0, //会议模式 0 自由发言模式 1 主持人模式
    },
    userData: JSON.parse(localStorage.getItem("userData")) || {
      avatarUrl: "",
      name: "",
      orgName: "",
      userCode: "",
      userType: "",
    },
    //设备信息
    deviceInfo: {
      cameraList: [],
      microphoneList: [],
      speakerList: [],
      currentMicrophoneId: "",
      currentSpeakerId: "",
    },
    //音频音量
    audioVolume: 0,
    lang: "zh-CN",
    _antLocale: {},
  },
  mutations: {
    SET_INFO(state, info) {
      state.info = info;
    },
    SET_MEETINGINFO(state, meetingInfo) {
      state.meetingInfo = meetingInfo;
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData;
      localStorage.setItem("userData", JSON.stringify(userData));
    },
    SET_CAMERA(state, data) {
      state.deviceInfo.cameraList = data;
    },
    SET_SPEAKER(state, data) {
      state.deviceInfo.speakerList = data;
    },
    SET_MICROPHONE(state, data) {
      state.deviceInfo.microphoneList = data;
    },

    SET_AUDIOVOLUME(state, data) {
      state.audioVolume = data;
    },

    SET_CURRENT_MICROPHONE(state, deviceId) {
      state.deviceInfo.currentMicrophoneId = deviceId;
    },
    SET_CURRENT_SPEAKER(state, deviceId) {
      state.deviceInfo.currentSpeakerId = deviceId;
    },
    [APP_LANGUAGE]: (state, lang, antd = {}) => {
      state.lang = lang;
      state._antLocale = antd;
      // storage.set(APP_LANGUAGE, lang);
      localStorage.setItem(APP_LANGUAGE, lang);
    },
  },
  actions: {
    setLang({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit(APP_LANGUAGE, lang);
        loadedLanguageAsync(lang)
          .then(() => {
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    setInfo({ commit }, info) {
      // console.log(info, "info");
      commit("SET_INFO", info);
    },
    setMeetingInfo({ commit }, data) {
      commit("SET_MEETINGINFO", data);
    },
    // setDeviceInfo({ commit }, data) {
    //   commit("SET_DEVICEINFO", data);
    // },
    setCameraList({ commit }, data) {
      commit("SET_CAMERA", data);
    },
    setSpeakerList({ commit }, data) {
      commit("SET_SPEAKER", data);
    },
    setMicrophoneList({ commit }, data) {
      commit("SET_MICROPHONE", data);
    },
    setAudioVolume({ commit }, data) {
      commit("SET_AUDIOVOLUME", data);
    },
    setCurrentMicrophoneId({ commit }, deviceId) {
      commit("SET_CURRENT_MICROPHONE", deviceId);
    },

    setCurrentSpeakerId({ commit }, deviceId) {
      commit("SET_CURRENT_SPEAKER", deviceId);
    },

    // fetchUserInfo({ commit }, userData) {
    //   commit('SET_USER_DATA', userData);
    // },
  },
  getters: {
    // info: state => state.info
  },
});
