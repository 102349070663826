import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    name: 'login',
    component: () => import('./view/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'home',
    component: () => import('./view/home.vue'),
    meta: {
      title: '首页'
    }
  },
  // {
  //   name: 'record',
  //   component: () => import('./view/record.vue'),
  //   meta: {
  //     title: '会议记录'
  //   }
  // },
  // {
  //   name: 'applyFor',
  //   component: () => import('./view/applyFor.vue'),
  //   meta: {
  //     title: '会议申请', keepAlive: true,
  //   }
  // },
  // {
  //   name: 'personnel',
  //   component: () => import('./view/personnel.vue'),
  //   meta: {
  //     title: '添加参会人员', keepAlive: true,
  //   }
  // },
  {
    name: 'conference',
    component: () => import('./view/conference.vue'),
    meta: {
      title: '会议中',
    }
  },
  {
    name: 'meeting',
    component: () => import('./view/meeting.vue'),
    meta: {
      title: '会议室',
    }
  }




];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
