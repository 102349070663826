const locale = {
  "btn.apply": "申请课程",
  "text.exit": "退出",
  "text.confirmExit": "确定退出？",
  "text.unknow": "未知",
  "text.waitApproval": "待审批",
  "text.reject": "审核未通过",
  "text.notStart": "未开始",
  "text.inProgress": "进行中",
  "text.end": "已结束",
  "text.Canceled": "已取消",
  "text.Invalid": "无效",
  "btn.record": "上课记录",
  // 我的申请
  "text.myApply": "我的申请",
  //课程名称
  "text.meetingName": "课程名称",
  //课程介绍
  "text.meetingIntroduction": "课程介绍",
  //课程开始时间
  "text.meetingStartTime": "课程开始时间",
  //课程结束时间
  "text.meetingEndTime": "课程结束时间",
  //选择课程开始时间
  "text.selectStartTime": "选择课程开始时间",
  //选择课程结束时间
  "text.selectEndTime": "选择课程结束时间",
  //选择课程时间
  "text.selectTime": "选择课程时间",
  //参加人员
  "text.attendPeople": "参加人员",
  //课程助教
  "text.mentor": "课程助教",
  //发言模式
  "text.speakMode": "发言模式",
  //自由发言模式
  "text.freeSpeakMode": "自由发言模式",
  //主持人模式
  "text.hostSpeakMode": "主持人模式",
  //允许非参加人员进入
  "text.allowNonAttend": "允许非参加人员进入",
  //允许提前进入
  "text.allowEarlyEntry": "允许提前进入",
  //翻译角色设置
  "text.translateRole": "翻译角色设置",
  //实时语音转写
  "text.realTimeTrans": "实时语音转写",
  //虚拟背景
  "text.virtualBackground": "虚拟背景",
  //导出课程纪要
  "text.exportMeetingSummary": "导出课程纪要",
  //提前进入时长（分钟）
  "text.earlyEntryTime": "提前进入时长（分钟）",
  //课程共享文件
  "text.meetingShareFile": "课程共享文件",
  //上传
  "text.upload": "上传",
  //提交申请
  "btn.submitApply": "提交申请",
  //请输入课程名称
  "text.pleaseInputMeetingName": "请输入课程名称",
  //请输入课程介绍
  "text.pleaseInputMeetingIntroduction": "请输入课程介绍",
  //搜索
  "text.search": "搜索",
  //确定
  "text.confirm": "确定",
  //已选择人数
  "text.selectedPeople": "已选择人数",
  //选择课程助教
  "text.selectMentor": "选择课程助教",
  //选择背景
  "text.selectBg": "选择背景",
  //审核中
  "text.approval": "审核中",
  //审核不通过
  "text.approvalNotPass": "审核不通过",
  //保存修改
  "text.save": "保存修改",
  //静音
  "text.mute": "静音",
  //摄像头
  "text.camera": "摄像头",
  //邀请
  "text.invite": "邀请",
  //成员
  "text.member": "成员",
  //更多
  "text.more": "更多",
  //收起
  "text.collapse": "收起",
  //录制
  "text.record": "录制",
  //录制中
  "text.recording": "录制中",
  //录制列表
  "text.recordList": "录制列表",
  //结束
  "text.End": "结束",
  //人员
  "text.personnel": "人员",
  //已加入
  "text.joined": "已加入",
  //未加入
  "text.notEnrolled": "未加入",
  //主持人
  "text.host": "主持人",
  //取消
  "text.cancel": "取消",
  // 发起人
  "text.initiator": "发起人",
  // 参加人员
  "text.attendee": "参加人员",
  // 开启语音权限
  "text.enablingVoiceRights": "开启语音权限",
  // 关闭语音权限
  "text.disableVoiceRights": "关闭语音权限",
  // 开启视频权限
  "text.enableVideoPermissions": "开启视频权限",
  // 关闭视频权限
  "text.disableVideoPermissions": "关闭视频权限",
  // 请先选择参加人员
  "text.pleaseSelectAttendee": "请先选择参加人员",
  // 背景选择
  "text.backgroundSelection": "背景选择",
  //默认背景图
  "text.defaultBackground": "默认背景图",
  //自定义背景图
  "text.customBackground": "自定义背景图",
  //不能为空
  "text.cannotBeEmpty": "不能为空",
  //离开课程
  "text.leaveMeeting": "离开课程",
  //翻译设置
  "text.translateSetting": "翻译设置",
  //语音转写
  "text.voiceTrans": "语音转写",
  //虚拟背景
  "text.virtualBackground": "虚拟背景",
  //文件
  "text.file": "文件",
  //字幕翻译
  "text.subtitleTranslate": "字幕翻译",
  //选择字幕
  "text.selectSubtitle": "选择字幕",
  //下载
  "text.download": "下载",
  // 暂未开启摄像头
  "text.cameraNotOpen": "暂未开启摄像头",
  //当前暂无课程
  "text.noMeeting": "当前暂无课程",
  //我的课程
  "text.myCourses": "我的课程",
  //直播列表
  "text.liveList": "直播列表",
  //暂无参加记录
  "text.noMeetingRecord": "暂无参加记录",
  //课程名称
  "text.CourseName": "课程名称",
  //课程时间
  "text.meetingTime": "课程时间",
  //课程时长
  "text.Duration": "时长",
  //参加人数
  "text.coursePeople": "课程人数",
  //助教人数
  "text.assistantPeople": "助教人数",
  //参加状态
  "text.Status": "参加状态",
  //状态
  "text.courseStatus": "课程状态",
  //可加入
  "text.canEnter": "可加入",
  //不可加入
  "text.cannotEnter": "不可加入",
  //课程日期
  "text.meetingDate": "课程日期",
  //起止时间
  "text.beginningAndEndingTime": "起止时间",
  //选择参加人员
  "text.selectAttendPeople": "选择参加人员",
  //请先选择参加人员
  "text.pleaseSelectAttendPeople": "请先选择参加人员",
  //点击上传
  "text.clickUpload": "点击上传",
  //是
  "text.yes": "是",
  //否
  "text.no": "否",
  //参加教师
  "text.attendTeacher": "参加教师",
  //参加学生
  "text.attendStudent": "参加学生",
  //学工号
  "text.studentNumber": "学工号",
  //姓名
  "text.name": "姓名",
  // 请先选择参加人员
  "text.participantsFirst": "请先选择参加人员",
  // 点击自定义虚拟背景
  "text.clickCustomizeBackground": "点击自定义虚拟背景",
  // 只能上传jpg/png文件
  "text.onlyJpgPng": "只能上传jpg/png文件",
  // 暂无翻译角色人员
  "text.noTranslatorsAvailable": "暂无翻译角色人员",
  // 请先选择参加人员
  "text.pleaseSelectParticipantsFirst": "请先选择参加人员",
  // 请输入背景名称
  "text.PleaseEnterBackground": "请输入背景名称",
  // 请输入当前图片背景名称
  "text.PleasePictureBackgroundName": "请输入当前图片背景名称",
  // 取消投屏
  "text.cancelProjectionScreen": "取消投屏",
  // 投屏设置
  "text.projectionScreenSetting": "投屏设置",
  // 全员静音
  "text.allMute": "全员静音",
  // 背景设置
  "text.backgroundSetting": "背景设置",
  // 声音设置
  "text.soundSetting": "声音设置",
  // 结束课程
  "text.concludeMeeting": "结束课程",
  // 语音转写及翻译设置
  "text.SpeechTransferAndranslationSettings": "语音转写及翻译设置",
  // 翻译中
  "text.InTranslation": "翻译中",
  // 当前发言人
  "text.CurrentSpeaker": "当前发言人",
  // 课程纪要
  "text.meetingSummary": "课程纪要",
  // 是否要关闭语音转写？
  "text.DoTurnOffSpeechTransliteration": "是否要关闭语音转写？",
 // 成员管理
 "text.memberSetting": "成员管理",
 // 助教
 "text.teachingAssistant": "助教",
 // 翻译
 "text.translate": "翻译",
// 暂无文件资料
"text.noFile": "暂无文件资料",
// 不使用
"text.nonuse": "不使用",
// 模糊背景
"text.FuzzyBackground": "模糊背景",
// 音频设置
"text.audioSettings": "音频设置",
// 扬声器
"text.loudspeaker": "扬声器",
// 麦克风
"text.microphone": "麦克风",
// 下载课程纪要
"text.DownloadMinutes": "下载课程纪要",
// 下载格式
"text.downloadFormat": "下载格式",
// 下载语言
"text.DownloadLanguage": "下载语言",
// 上海市气功研究所
"text.projectName": "上海市气功研究所",
// 上海中医药大学附属的事业单位
"text.projectUnit": "上海中医药大学附属的事业单位",
// 简介
"text.projectintroduce": "上海市气功研究所是国内规模最大的气功专业研究机构。上海市气功研究所成立于1985年，其前身是上海市中医研究所。从成立的二十多年里，本所坚持科学态度、宏扬科学精神，对祖国传统文化瑰宝之一的气功开展了一系列科学研究，取得了一定的成绩，积累了一定的工作基础和经验。",
// 公告
"text.announcement1": "你好，最近课程为",
"text.announcement2": "召开",
"text.announcement3": "，请您安排好时间，准时加入。",
"text.announcement4": "你好，当前正在进行",
"text.announcement5": "，请您准时到加入。",
// 直播名称
"text.liveName":"直播名称",
// 主讲人
"text.keynoteSpeaker":"主讲人",
// 预告时间
"text.warningTime":"预告时间",
// 开播时间
"text.startTime":"开播时间",
// 直播状态
"text.liveStatus": "直播状态",
// 暂无直播记录
"text.noLiveRecords": "暂无直播记录",
// 录制文件
"text.recordingFile": "录制文件",
// 刷新
"text.refresh": "刷新",
// 关闭
"text.close": "关闭",
// 语音权限
"text.voiceAuthority": "语音权限",
// 视频权限
"text.videoPermission": "视频权限",
// 设置为主屏
"text.setHomeScreen": "设置为主屏",
// 操作
"text.operate": "操作",
// 查看直播
"text.viewLiveStream": "查看直播",
// 查看回放
"text.viewPlayback": "查看回放",
};

export default {
  // ...components,
  ...locale,
};
