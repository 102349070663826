import "amfe-flexible";
// import '../../vue2/src/main';
import Vue from "vue";
import App from "./App";
import TRTC from 'trtc-sdk-v5';
// import TRTC from "trtc-js-sdk";
import store from "./store";
import i18n from './locales';
import { router } from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import Vant from 'vant';
// import 'vant/lib/index.css';

Vue.config.productionTip = false;
// TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG);
Vue.use(ElementUI);
// Vue.use(Vant);
new Vue({
  router,
  store,
  i18n,
  el: "#app",
  render: (h) => h(App),
});
