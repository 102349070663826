const locale = {
  "btn.apply": "apply for course",
  "text.exit": "Exit",
  "text.confirmExit": "Confirm Exit?",
  "text.unknow": "unknow",
  "text.waitApproval": "waitApproval",
  "text.reject": "reject",
  "text.notStart": "notStart",
  "text.inProgress": "inProgress",
  "text.end": "end",
  "text.Canceled": "Canceled",
  "text.Invalid": "Invalid",
  "btn.record": "record",
  // 我的申请
  "text.myApply": "My Apply",
  //课程名称
  "text.meetingName": "Course Name",
  //课程介绍
  "text.meetingIntroduction": "Course Introduction",
  //课程开始时间
  "text.meetingStartTime": "Course Start Time",
  //课程结束时间
  "text.meetingEndTime": "Course End Time",
  //选择课程开始时间
  "text.selectStartTime": "SelectStartTime",
  //选择课程结束时间
  "text.selectEndTime": "SelectEndTime",
  //选择课程时间
  "text.selectTime": "SelectTime",
  //参加人员
  "text.attendPeople": "AttendPeople",
  //课程助教
  "text.mentor": "Mentor",
  //发言模式
  "text.speakMode": "SpeakMode",
  //自由发言模式
  "text.freeSpeakMode": "FreeSpeak",
  //主持人模式
  "text.hostSpeakMode": "Host",
  //允许非参加人员进入
  "text.allowNonAttend": "AllowNonAttend",
  //允许提前进入
  "text.allowEarlyEntry": "AllowEarlyEntry",
  //翻译角色设置
  "text.translateRole": "TranslateRole",
  //实时语音转写
  "text.realTimeTrans": "RealTimeTrans",
  //虚拟背景
  "text.virtualBackground": "VirtualBackground",
  //导出课程纪要
  "text.exportMeetingSummary": "Export Course Summary",
  //提前进入时长（分钟）
  "text.earlyEntryTime": "Early Entry Time",
  //课程共享文件
  "text.meetingShareFile": "Course Share File",
  //上传
  "text.upload": "Upload",
  //提交申请
  "btn.submitApply": "SubmitApply",
  //请输入课程名称
  "text.pleaseInputMeetingName": "Please Input Course Name",
  //请输入课程介绍
  "text.pleaseInputMeetingIntroduction": "Please Input Course Introduction",
  //搜索
  "text.search": "Search",
  //确定
  "text.confirm": "Confirm",
  //已选择人数
  "text.selectedPeople": "Selected People",
  //选择课程助教
  "text.selectMentor": "Select Mentor",
  //选择背景
  "text.selectBg": "Select Background",
  //审核中
  "text.approval": "Approval",
  //审核不通过
  "text.approvalNotPass": "Approval Not Pass",
  //保存修改
  "text.save": "Save",
  //静音
  "text.mute": "Mute",
  //摄像头
  "text.camera": "Camera",
  //邀请
  "text.invite": "Invite",
  //成员
  "text.member": "Member",
  //更多
  "text.more": "More",
  //收起
  "text.collapse": "Collapse",
  //录制
  "text.record": "Record",
  //录制中
  "text.recording": "Recording",
  //录制列表
  "text.recordList": "Record List",
  //结束
  "text.End": "End",
  //人员
  "text.personnel": "Personnel",
  //已加入
  "text.joined": "Joined",
  //未加入
  "text.notEnrolled": "NotEnrolled",
  //主持人
  "text.host": "Host",
  //取消
  "text.cancel": "Cancel",
  //发起人
  "text.initiator": "Initiator",
  //参加人员
  "text.attendee": "Attendee",
  // 开启语音权限
  "text.enablingVoiceRights": "Enabling voice",
  // 关闭语音权限
  "text.disableVoiceRights": "Disable voice",
  // 开启视频权限
  "text.enableVideoPermissions": "Enable video",
  // 关闭视频权限
  "text.disableVideoPermissions": "Disable video",
  // 请先选择参加人员
  "text.pleaseSelectAttendee": "Please select attendees",
  // 背景选择
  "text.backgroundSelection": "Background selection",
  //默认背景图
  "text.defaultBackground": "Default background",
  //自定义背景图
  "text.customBackground": "Custom background",
  //不能为空
  "text.cannotBeEmpty": "Cannot be empty",
  //离开课程
  "text.leaveMeeting": "Leave",
  //翻译设置
  "text.translateSetting": "TranslateSetting",
  //语音转写
  "text.voiceTrans": "VoiceTrans",
  //虚拟背景
  "text.virtualBackground": "VirtualBackground",
  //文件
  "text.file": "File",
  //字幕翻译
  "text.subtitleTranslate": "SubtitleTranslate",
  //选择字幕
  "text.selectSubtitle": "SelectSubtitle",
  //下载
  "text.download": "Download",
  // 暂未开启摄像头
  "text.cameraNotOpen": "Camera not open",
  //当前暂无课程
  "text.noMeeting": "No Course",
  //我的参加记录
  "text.myCourses": "my Courses",
  //直播列表
  "text.liveList": "Live List",
  //暂无参加记录
  "text.noMeetingRecord": "No Meeting Record",
  //课程名称
  "text.CourseName": "Course Name",
  //课程时间
  "text.CourseTime": "Course Time",
  //课程时长
  "text.Duration": "Duration",
  //参加人数
  "text.coursePeople": "coursePeople",
  //助教人数
  "text.assistantPeople": "AssistantPeople",
  //参加状态
  "text.Status": "Status",
  //状态
  "text.courseStatus": "courseStatus",
  //可加入
  "text.canEnter": "canEnter",
  //不可加入
  "text.cannotEnter": "cannotEnter",
  //课程日期
  "text.meetingDate": "CourseDate",
  //起止时间
  "text.beginningAndEndingTime": "beginning and ending time",
  //选择参加人员
  "text.selectAttendPeople": "SelectAttendPeople",
  //请先选择参加人员
  "text.pleaseSelectAttendPeople": "Please Select AttendPeople",
  //点击上传
  "text.clickUpload": "Click Upload",
  //是
  "text.yes": "Yes",
  //否
  "text.no": "No",
  //参加教师
  "text.attendTeacher": "AttendTeacher",
  //参加学生
  "text.attendStudent": "AttendStudent",
  //学工号
  "text.studentNumber": "SNo",
  //姓名
  "text.name": "Name",
  // 请先选择参加人员
  "text.participantsFirst": "Please select participants first",
  // 点击自定义虚拟背景
  "text.clickCustomizeBackground": "Click Customize Virtual Background",
  // 只能上传jpg/png文件
  "text.onlyJpgPng": "Only jpg/png files can be uploaded",
  // 暂无翻译角色人员
  "text.noTranslatorsAvailable": "There are no translators available",
  // 请先选择参加人员
  "text.pleaseSelectParticipantsFirst": "Please select participants first",
  // 请输入背景名称
  "text.PleaseEnterBackground": "Please enter background",
  // 请输入当前图片背景名称
  "text.PleasePictureBackgroundName": "Please enter the current picture background name",
  // 取消投屏
  "text.cancelProjectionScreen": "cancel projection screen",
  // 投屏设置
  "text.projectionScreenSetting": "projection screen setting",
  // 全员静音
  "text.allMute": "all mute",
  // 背景设置
  "text.backgroundSetting": "background setting",
  // 声音设置
  "text.soundSetting": "sound setting",
  // 结束课程
  "text.concludeMeeting": "conclude a course",
  // 语音转写及翻译设置
  "text.SpeechTransferAndranslationSettings": "Speech transfer and translation Settings",
  // 翻译中
  "text.InTranslation": "in translation",
  // 当前发言人
  "text.CurrentSpeaker": "current speaker",
  // 课程纪要
  "text.meetingSummary": "course summary",
  // 是否要关闭语音转写？
  "text.DoTurnOffSpeechTransliteration": "Do you want to turn off voice translation? ",
  // 成员管理
  "text.memberSetting": "member setting",
  // 助教
  "text.teachingAssistant": "teaching assistant",
  // 翻译
  "text.translate": "translate",
  // 暂无文件资料
  "text.noFile": "No file information is available",
  // 不使用
  "text.nonuse": "nonuse",
  // 模糊背景
  "text.FuzzyBackground": "fuzzy background",
  // 音频设置
  "text.audioSettings": "audio settings",
  // 扬声器
  "text.loudspeaker": "loudspeaker",
  // 麦克风
  "text.microphone": "microphone",
  // 下载课程纪要
  "text.DownloadMinutes": "Download the minutes",
  // 下载格式
  "text.downloadFormat": "download format",
  // 下载语言
  "text.DownloadLanguage": "Download language",
  // 上海市气功研究所
  "text.projectName": "Shanghai Qigong Research Institute",
  // 上海中医药大学附属的事业单位
  "text.projectUnit": "Shanghai University of Traditional Chinese Medicine affiliated institution",
  // 简介
  "text.projectintroduce": "Shanghai Qigong Research Institute is the largest professional Qigong research institution in China. Shanghai Qigong Research Institute was founded in 1985, its predecessor was Shanghai Institute of Traditional Chinese Medicine. Since its establishment more than 20 years ago, the institute has adhered to the scientific attitude and the scientific spirit, and carried out a series of scientific researches on Qigong, one of the treasures of the traditional culture of the motherland, and has made certain achievements and accumulated certain work foundation and experience.",
  // 公告
  "text.announcement1": "Hello, the latest course is",
  "text.announcement2": "convoke",
  "text.announcement3": ",Please arrange your time and attend the meeting on time.",
  "text.announcement4": "Hello, it's currently underway",
  "text.announcement5": ",Please attend the meeting on time.",
  // 直播名称
  "text.liveName": "Livestreaming name",
  // 主讲人
  "text.keynoteSpeaker": "keynote speaker",
  // 预告时间
  "text.warningTime": "warning time",
  // 开播时间
  "text.startTime": "start time",
  // 直播状态
  "text.liveStatus": "live status",
  // 暂无直播记录
  "text.noLiveRecords": "No live broadcast records",
  // 录制文件
  "text.recordingFile": "recording file",
  // 刷新
  "text.refresh": "refresh",
  // 关闭
  "text.close": "close",
  // 语音权限
  "text.voiceAuthority": "voice authority",
  // 视频权限
  "text.videoPermission": "Video permission",
  // 设置为主屏
  "text.setHomeScreen": "Set home screen",
  // 操作
  "text.operate": "operate",
  // 查看直播
  "text.viewLiveStream": "view liveS stream",
  // 查看回放
  "text.viewPlayback": "view playback",
};
export default {
  // ...components,
  ...locale,
};
